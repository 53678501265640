.spacer img {
  width: 40px;
  border: 4px dotted rgb(0, 153, 255);
  border-radius: 50%;
}

.spacer img:hover {
  width: 45px;
  border: none
}

.spacer .img-container {
  height: 50px;
}

@media  (max-width:576px){
  .spacer .img-container {
    padding: 0;
  }
}


