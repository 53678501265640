.pet {
    margin: auto;
    height: 10rem;
    width: 10rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    animation-name: pet;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-direction: alternate;

}

.circle1 {
    border-radius: 50%;
    color:green;
    display:flex;
    border: 2px solid white;
    animation-name: circle1;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-delay: 10s;
}
.circle2 {
    border-radius: 50%;
    position: absolute;
    display: flex;
    color:green;
    border: 1px solid white;
    animation-name: circle2;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.circle3 {
    margin: auto;
    text-align: center;
    border: 1px solid white;
    border-radius:50%;
    animation-name: circle3;
    animation-duration:25s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}


@keyframes pet {
    from {
        margin:0;
    }

    25% {
        margin: 5vh 0 0 80vw;
    }

    50% {
        margin: 35vh 0 0 0;
    }

    to {
        margin: 5vh 10vw 0 100vh;
    }

}


@keyframes circle1 {
    from {
        height: 4rem;
        width: 4rem;
        border-color: rgba(255, 255, 255, 0);
        background-image: url("/img/water.png ");
        opacity: 0
    }

    to {
        height: 10rem;
        width: 10rem;
        border-color: rgb(47, 165, 255);
        background-image: url("/img/banners/background-0.jpg ");
        opacity: 0.5
    }
}


@keyframes circle2 {
    from {
        height: 5rem;
        width: 5rem;
        border-color: #00b7ff83;
        background-image: url("/img/water.png ");
        opacity: 0
    }

    to {
        height: 8rem;
        width: 8rem;
        border-color: rgba(47, 165, 255, 0.688);
        background-image: url("/img/banners/background-0.jpg ");
        opacity: 0.5
    }
}

@keyframes circle3 {
    from {
        height: 1rem;
        width: 1rem;
        border-width: 2px;
        border-color: white;
        background-color: rgba(41, 158, 247, 0.805);
    }

    to {
        height: 3rem;
        width: 3rem;
        border-color: rgba(47, 165, 255, 0.798);;
    }
}


@media screen and (max-width: 425px) {

    @keyframes pet {
        from {
            margin:30vh 2vw 0 30vw;
        }

        25% {
            margin: 25vh 0 12vw 6vw;
        }

        50% {
            margin: 30vh 6vw 0 25vw;
        }

        to {
            margin: 20vh 10vw 0 30vw;
        }

    }
 
}
    