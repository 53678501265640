.serviceCards {
    display: flex;
    flex-wrap: wrap;
}

.serviceCards .serviceCard {
    width: 600px;
    text-align: center;
    margin:   1rem auto ;
    border: 2px solid rgb(90,202, 234);;
    border-radius:20px;
    padding: 1rem;
}

.serviceCards .serviceCard .serviceCard-description {
    font-weight: 600;
    color: rgb(90,202, 234);
}


.serviceCards .serviceCard .serviceCard-price {
    font-weight: 600;
    font-size: 2rem;
    color: white;
}


.serviceCards .serviceCard .serviceCard-task {
    font-weight: 400;
    font-size: 0.75rem;
}


.serviceCards .serviceCard img {
    width: 100%;
    margin-top: 4.5rem;
}

.serviceCards .serviceCard .serviceCard-title,.serviceCard-description {
    text-wrap: balance;
}

