html, body {
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  text-align: center;
  font-size: 2rem !important;
}

h2 {
  text-align: center;
  font-size: 2rem;
}

p {
  font-size: 1.2rem;
}

@media screen and (max-width: 425px) {
  h2 {
    font-size: 2rem;
  }
  
  h3 {
    font-size: 1.5rem !important;
  }

  h4 {
    font-size: 1.4rem !important;
  }
}
