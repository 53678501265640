.planet-sight {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.planet-sight .planet {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
}

.planet-sight .planet img {
    width: 100%;
}