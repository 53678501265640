
#web-gl-scene {
  background-size: cover;
}

#web-gl-scene img{
 position: fixed;
 width: 50px;
 top: 25px;
 left:25px;
}
