#navbar {
  background-color: rgba(0, 0, 0, 0.75); /* Black background color */
  position: fixed; /* Make it stick/fixed */
  top: 0; /* Stay on top */
  min-width: 100%;
  transition: top 0.3s; /* Transition effect when sliding down (and up) */
  z-index:5002 ;

}

/* Style the navbar links */
#navbar a {
  float: left;
  display: block;
  color: white;
  text-align: center;
  padding: 15px;  
  text-decoration: none;
}

#navbar a:hover {
  color: rgb(255, 255, 255);
  border-radius: 2px;
  font-weight: 600;
}

#navbar a:first-child:hover {
  border-radius: 0 2px 2px 0;

}