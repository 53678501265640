.login {
    position: relative;
    overflow-x: hidden;
}

.login .authContainer {
    position: absolute;
    top:0;
    left: 0;
    min-height: 100dvh ;
    min-width: 100vw;
    display: flex;
    justify-content: flex-start;    
    align-items: center;
    flex-wrap: wrap;
    padding-left: 5vw;
    background-color: rgba(0, 0, 0, 0.328);
}


.login .authContainer .authBox {
    min-width: 260px;
    min-height: fit-content;
    padding: 2rem;
    background-color: rgba(255, 255, 255, 0.215);
    border-radius: 25px;
}

.login .authContainer .logo {
    text-align: center;
    height: 200px;
}

.login .authContainer .logo img{
    height: 200px;
}

.login .authContainer input {
    background-color: rgba(255, 255, 255, 0.401);
    border-radius: 5px;
    border: 2px solid rgba(88, 88, 88, 0.486);
    color: rgba(255, 255, 255, 0.631);
    padding: 8px;
    font-weight: 800;
    font-size: 1.2rem;
}

@media(max-width: 992px){
    .login .authContainer {
        justify-content: center;    
        padding: 0;
    }
    
    .login .authContainer .authBox {
        background-color:transparent;
        padding : 0 2rem;
    }
    
}
