.page--integration {
    padding: 2rem;
    position: relative;
    height: 70vh;
    background-image: url("/img/banners/background-1.jpg");
    z-index: -2;
    overflow:hidden;
    max-width: 100%;
}

.page--integration h2 {
    text-align: center;
    Font-weight: bold;
    font-size:2rem;
    margin-top:2rem;
    color: rgb(90,202, 234);
    
}

.page--integration p {
    text-align: center;
    Font-weight: bold;
}

.page--integration .pet-park {
    position:absolute;
    top:0;
    z-index:-1;
    overflow: hidden;
}