.header {
  background-image: url("/img/banners/background-0.jpg");
  background: linear-gradient(to right, #1E1E1E, #4A4A4A);
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.pet-park {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;


}

.logo {
  height: 40vmin;
  pointer-events: none;
  /* opacity: 0.5; */
}

.header img{
 background-color: rgba(0, 0, 0, 0.301);
 border-radius: 25px;
 box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.575);
}

.logo:hover {
  opacity: 1;
}

@media (prefers-reduced-motion: no-preference) {
  .logo {
    animation: logo-spin infinite 20s linear;
  }
}
/* 
@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@media screen and (max-width: 768px) {
  
  .header{
    background-position: center;
    background-attachment: unset;
  }

  .header h2 {
    font-size: 2rem;
  }
  .header h3 {
    font-size: 1.1rem;
  }
}
