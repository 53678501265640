.priceCards {
    display: flex;
    flex-wrap: wrap;
}

.priceCards .priceCard {
    width: 300px;
    text-align: center;
    margin:   1rem auto ;
    border: 2px solid rgb(90,202, 234);;
    border-radius:20px;
    padding: 1rem;
}

.priceCards .priceCard .priceCard-title.gold {
    font-weight: 600;
    color:  rgb(234, 227, 90);;
}


.priceCards .priceCard.gold{
    border: 2px solid rgb(234, 227, 90);;
}

.priceCards .priceCard .priceCard-tjm {
    font-weight: 600;
    font-size: 2rem;
    color: white;
}


.priceCards .priceCard .priceCard-task {
    font-weight: 600;
    color: rgb(90,202, 234);
}

.priceCards .priceCard .priceCard-task.gold {
    color:  rgb(234, 227, 90);;
}