.iframe-container {
  position: relative;
  width: 100%;
  padding-top: 62.5%;
  height: auto;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
