.about .profil {
  background-image: url("/img/banners/background-0.jpg ");
  background-size: cover;
  border-radius: 25px;
  padding: 25px;
  border: 2px solid beige;
  text-shadow: 2px 2px 2px black;
  color: white;
  min-height: 425px;
  overflow: hidden
}

.about .profil img {
  width: 25%;
  float: left;
  margin-right: 15px;
  border-radius: 5px;
}

.about h3 {
  font-size: 2rem;
}

@media screen and (max-width: 768px) {
  .about .introduction {
    font-size: 2rem;
  }

  .about .profil img {
  width: 100%;
  padding:2em;
  float: none;
  margin: none;
  margin-bottom: 25px;
  }
}
