body {
  font-family: "Open Sans", sans-serif;
}

.dropdown {
  border: 1px solid black;
  background-color: white;
  position: absolute;
  left: 0;
  z-index: 5;
  width: 100%;
}

.dropdown li {
  cursor: pointer;
  list-style-type: none;
  height: 50px;
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.dropdown li span {
  font-weight: bold;
}

.dropdown li:hover {
  background-color: lightsteelblue;
}

.dropdown ul {
  margin: 0;
  padding: 0;
  width: 100%;
}

.panel {
  width: 800px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.panel input[type="text"] {
  height: 50px;
  min-width: 100%;
  margin-bottom: 15px ;
  border-radius: 5px;
  border: none;
  border: 1px solid lightgray;
  font-size: 15px;
}

.sep {
  margin-top: 20px;
}

#map {
  height: 500px;
}

/* Leaflet */

.leaflet-popup-content-wrapper {
  background-color: rgba(0,0,0,0.7);
  color: white;
  height: 3rem;
  font-weight: 600;
  text-align: center;
}

.leaflet-popup-tip {
  background-color: rgba(0,0,0,0.6);
}