.pricing .container-pricing {
   margin: 2rem; 
}

.pricing .container-services {
   margin: 2rem;
}

.pricing .container-services p{
   margin: 2rem;
   font-size: 1.5rem;
   text-align: center;
   font-weight: 600;
}
